<template>
  <div>
    <div class="pl-3 pr-3 pt-3 pb-3">
      <h4 class="m-0">Documents</h4>
      <div class="text-muted">
        <small>Documents du système qualité Arkadia Group</small>
      </div>
    </div>
    <div class="pl-3 pr-3 d-none d-md-block">
      <h5 class="m-0">Catégories</h5>
      <LeftBarItem text="Récents" link="recents"></LeftBarItem>
      <LeftBarItem text="Tous les documents" link="all"></LeftBarItem>
      <LeftBarItem text="Manuels" link="manuels"></LeftBarItem>
      <LeftBarItem text="Processus" link="processus"></LeftBarItem>
      <LeftBarItem text="Procédures" link="procedures"></LeftBarItem>
      <LeftBarItem text="Formulaires" link="formulaires"></LeftBarItem>
      <LeftBarItem text="Supports" link="supports"></LeftBarItem>
      <LeftBarItem text="Supports de causerie" link="causerie"></LeftBarItem>
      <LeftBarItem text="Trames" link="trames"></LeftBarItem>
    </div>

    <div class="pt-3 pl-3 pr-3 d-none d-md-block">
      <h5
        class="m-0 cursor-pointer"
        @click="showRaccourcis = !showRaccourcis"
        :class="{ 'font-weight-bold': showRaccourcis }"
      >
        Raccourcis
      </h5>
      <div v-if="showRaccourcis">
        <h6 class="mt-3">Processus</h6>
        <div
          v-for="file in this.contextData.raccourcis"
          :key="'processus-' + file.id"
        >
          <LeftBarItem
            v-if="file.type === 1"
            :text="file.libelle"
            :link="file.code"
          ></LeftBarItem>
        </div>

        <h6 class="mt-3">Administratif</h6>
        <div
          v-for="file in this.contextData.raccourcis"
          :key="'admin-' + file.id"
        >
          <LeftBarItem
            v-if="file.type === 2"
            :text="file.libelle"
            :link="file.code"
          ></LeftBarItem>
        </div>

        <h6 class="mt-3">Documents</h6>
        <div
          v-for="file in this.contextData.raccourcis"
          :key="'docs-' + file.id"
        >
          <LeftBarItem
            v-if="file.type === 3"
            :text="file.libelle"
            :link="file.code"
          ></LeftBarItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftBarItem from "@/components/documents/LeftBarItem.vue";

export default {
  components: {
    LeftBarItem
  },
  data() {
    return {
      showActivites: true,
      showMangement: true,
      showRaccourcis: true,
      showRaccourcis2: true,
      contextData: { raccourcis: {} }
    };
  },
  methods: {
    getRaccourcis: function() {
      this.loading = true;
      this.$http
        .get("/documents_qsser/raccourcis")
        .then(response => (this.contextData.raccourcis = response.data))
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.getRaccourcis();
  }
};
</script>
